	/*
  	Flaticon icon font: Flaticon
  	Creation date: 17/09/2019 02:58
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-play-button:before { content: "\f100"; }
.flaticon-play-button-1:before { content: "\f101"; }
.flaticon-play-button-2:before { content: "\f102"; }
.flaticon-back:before { content: "\f103"; }
.flaticon-next:before { content: "\f104"; }
.flaticon-right-quote:before { content: "\f105"; }
.flaticon-left-quote:before { content: "\f106"; }
.flaticon-home:before { content: "\f107"; }
.flaticon-archive:before { content: "\f108"; }
.flaticon-window:before { content: "\f109"; }
.flaticon-waiter:before { content: "\f10a"; }
.flaticon-like:before { content: "\f10b"; }
.flaticon-like-1:before { content: "\f10c"; }
.flaticon-growth:before { content: "\f10d"; }
.flaticon-hand:before { content: "\f10e"; }
.flaticon-coin:before { content: "\f10f"; }
.flaticon-rug:before { content: "\f110"; }
.flaticon-carpet:before { content: "\f111"; }
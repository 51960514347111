/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
	@include background-style("../../images/footer-bg.jpg", center top, cover, no-repeat, local);
	position: relative;
	font-size: 15px;
	overflow: hidden;
	z-index: 1;

	&:before {
		content: "";
		background: transparentize($theme-primary-color, 0.3);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	ul {
		list-style: none;
	}

	p,
	li {
		color: $white;
	}


	.upper-footer {
		padding: 90px 0;

		@include media-query(991px) {
			padding: 90px 0 20px;
		}

		@include media-query(767px) {
			padding: 80px 0 10px;
		}

		.col {
			@include media-query(991px) {
				min-height: 235px;
				margin-bottom: 70px;
			}

			@include media-query(767px) {
				min-height: auto;
				margin-bottom: 60px;
			}
		}
	}

	.widget-title {
		margin-bottom: 30px;

		@include media-query(767px) {
			margin-bottom: 20px;
		}
	}

	.widget-title h3 {
		font-size: 18px;
		
		font-weight: bold;
		color: $white;
		margin: 0;
		padding-bottom: 0.4em;
		text-transform: capitalize;
		position: relative;

		@include media-query(991px) {
			padding-bottom: 0;
		}
	}

	/*** about-widget ***/
	.about-widget {
		.logo {
			max-width: 180px;
		}

		p {
			margin-bottom: 0.8em;
			line-height: 1.9em;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	/*** link-widget ***/
	.link-widget {
		overflow: hidden;

		@include widther(1200px) {
			padding-left: 20px;
		}

		@include media-query(1199px) {
			padding-left: 20px;
		}

		@include media-query(991px) {
			padding-left: 0;
		}

		@include media-query( 767px ) {
	       max-width: 350px;
	    }

		ul {
			width: 50%;
			float: left;
		}

		ul li {
			position: relative;
		}

		ul a {
			color: #eaeaea;
		}

		ul a:hover,
		ul li:hover:before {
			text-decoration: underline;
		}

		ul > li + li {
			margin-top: 15px;
		}
	}

	/*** contact-widget ***/
	.contact-widget {
		@include widther(1200px) {
			padding-left: 25px;
		}

		ul {
			margin-top: 25px;
		}

		ul li {
			position: relative;
		}

		ul > li + li {
			margin-top: 15px;
		}

		li span {
			font-weight: bold;
		}
	}

	/*** newsletter-widget ***/
	.newsletter-widget {
		form {
			margin-top: 25px;
			position: relative;

			input {
				background-color: $white;
				height: 50px;
				color: $text-color;
				padding: 6px 20px;
				border-radius: 5px;
				border: none;
				box-shadow: none;
			}

			.submit {
				position: absolute;
				right: 20px;
				top: 55%;
				@include translatingY();

				button {
					background: transparent;
					border: 0;
					outline: 0;
					font-size: 20px;
					color: $theme-primary-color;
					margin-top: -2px;
					position: relative;
					right: -5px;
				}
			}
		}
	}


	/*** lower-footer ***/
	.lower-footer {
		text-align: center;
		position: relative;

		.row {
			padding: 20px 0;
			position: relative;

			.separator {
				background: rgba(255, 255, 255, 0.05);
				width: calc(100% - 30px);
				height: 1px;
				position: absolute;
				left: 15px;
				top: 0;
			}
		}

		.copyright {
			display: inline-block;
			float: left;
			font-size: 14px;
			margin: 0;

			@include media-query(991px) {
				float: none;
				display: block;
				margin: 0 0 20px;
			}

			a {
				color: $white;
				text-decoration: underline;
			}
		}

		.extra-link {
			display: inline-block;
			float: right;
			overflow: hidden;

			@include media-query(991px) {
				float: none;
				display: block;
				text-align: center;
			}
		}

		.extra-link ul {
			overflow: hidden;
			list-style: none;

			@include media-query(991px) {
				display: inline-block;
			}

			li {
				float: left;
			}

			> li + li {
				margin-left: 35px;
				position: relative;

				@include media-query(767px) {
					margin-left: 15px;
				}

				&:before {
					content: "";
					background: $white;
					width: 15px;
					height: 1px;
					position: absolute;
					left: -25px;
					top: 11px;

					@include media-query(767px) {
						display: none;
					}
				}
			}

			a {
				font-size: 14px;
				color: $white;
			}

			a:hover {
				text-decoration: underline;
			}
		}

	}
}
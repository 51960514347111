/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,600,700,900&display=swap');

// fonts
$base-font-size: 16;
$base-font: 'Muli', sans-serif;



// color
$dark-gray: #2a2a2a;
$white: #fff;
$black: #000;
$blue: #0e3c7b;
$blue2: #237cf5;
$mid-blue: #57667e;
$yellow: #feea2b;

$theme-primary-color: $blue2;
$theme-secondary-color: $yellow;
$body-bg-color: $white;
$section-bg-color: #edecec;
$text-color: $mid-blue;
$heading-color: $blue;

/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
    font-size: $base-font-size + px;
}

body {
    font-family: $base-font;
    background-color: $body-bg-color;
    font-size: 16px;
    color: $text-color;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    @include media-query(767px) {
        font-size: 14px;
    }
}

p {
    color: $text-color;
    line-height: 1.6em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    font-weight: 600;
}

ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

a {
    text-decoration: none;
    @include transition-time(0.2s);
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}


/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.page-wrapper {
    position: relative;
    overflow: hidden;
}
.App{
    position: relative;
    overflow: hidden;
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 100px 0;

    @include media-query(991px) {
        padding: 90px 0;
    }

    @include media-query(767px) {
        padding: 80px 0;
    }
}

.p-t-0 {
    padding-top: 0 !important;
}

/*** contact form error handling ***/
.contact-validation-active {
    .error-handling-messages {
        width: 100% !important;
        margin-top: 15px !important;
    }

    label.error {
        color: red;
        font-weight: normal;
        margin: 5px 0 0 0;
        text-align: left;
        display: block;
    }

    #loader {
        display: none;
        margin-top: 10px;

        i {
            font-size: 30px;
            color: $theme-primary-color;
            display: inline-block;
            -webkit-animation: rotating linear 2s infinite;
            animation: rotating linear 2s infinite;
        }
    }

    #success,
    #error {
        width: 100%;
        color: $white;
        padding: 5px 10px;
        font-size: 16px;
        text-align: center;
        display: none;

        @include media-query(767px) {
            font-size: 15px;
        }
    }

    #success {
        background-color: lighten(green, 5%);
        border-left: 5px solid green;
        margin-bottom: 5px;
    }

    #error {
        background-color: lighten(red, 5%);
        border-left: 5px solid red;
    }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 5s alternate infinite;
    animation: rotating 5s alternate infinite;
}

.errorMessage {
    color: red;
}

/** for popup image ***/
.mfp-wrap {
    background-color: transparentize($black, 0.1);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}
/*--------------------------------------------------------------
	#service single page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#6.1	service-single-section
--------------------------------------------------------------*/
.service-single-section {
	.service-single-content {
		@include widther(1200px) {
			padding-left: 20px;
		}

		.theme-btn {
			margin-top: 10px;
		}

		h2 {
			font-size: 34px;
			font-weight: bold;
			margin: 0 0 0.7em; 
			text-transform: capitalize;

			@include media-query(991px) {
				font-size: 25px;
			}

			@include media-query(767px) {
				font-size: 22px;
			}
		}

		h3 {
			font-size: 24px;
			font-weight: bold;
			text-transform: capitalize;
			margin: 0 0 1em;
			
			@include media-query(991px) {
				font-size: 22px;
			}

			@include media-query(767px) {
				font-size: 20px;
			}
		}

		p {
			margin-bottom: 1.3em;
		}

		blockquote {
			background-color: #e9f2fb;
			font-size: 17px;
			padding: 45px 25px 45px 95px;
			margin: 55px 0 80px;
			border: 0;
			position: relative;
			font-style: italic;

			&:before {
				font-family: "Flaticon";
				content: "\f106";
				font-size: 45px;
				color: $theme-primary-color;
				position: absolute;
				left: 30px;
				top: 35px;
				opacity: 0.5;
				font-style: normal;

				@include media-query(991px) {
					display: none;
				}
			}

			@include media-query(991px) {
				font-size: 14px;
				padding: 35px 25px;
				margin: 45px 0 70px;
			}

			p {
				margin-bottom: 0.5em;
			}

			> span {
				font-size: 13px;
			}
		}

		.service-single-img {
			position: relative;
			margin-bottom: 60px;

			@include media-query(991px) {
				margin-bottom: 50px;
			}
		}

		.benefit {
			margin-bottom: 50px;

			@include media-query(767px) {
				margin-bottom: 30px;
			}
		}

		.benefit .details {
			width: calc(100% - 270px);
			float: right;
			padding-left: 45px;

			@include media-query(767px) {
				width: 100%;
				float: none;
				padding-left: 0;
			}
		}

		.benefit .img-holder {
			width: 270px;
			float: left;

			@include media-query(767px) {
				width: 100%;
				float: none;
				margin: 45px 0;
			}

			img {
				border: 2px solid #e9f2fb;
			}
		}

		ul {
			margin-bottom: 30px;
			list-style-type: none;
		}

		ul li {
			position: relative;
			padding-left: 35px;
			font-weight: 600;

			span {
				background: $theme-primary-color;
				width: 25px;
				height: 25px;
				line-height: 25px;
				text-align: center;
				border-radius: 25px;
				font-size: 14px;
				color: $white;
				position: absolute;
				left: 0;
				top: 0;
				text-align: center;
			}
		}

		ul > li + li {
			margin-top: 15px;
		}
	}

	/*** service single tab ***/
	.service-single-tab {
		margin: 50px 0;

		@include media-query(991px) {
			margin: 40px 0;
		}
	}

	.service-single-tab .nav {
		overflow: hidden;
		margin: 0;

		li {
			float: left;
			padding-left: 0;
		}

		> li + li {
			margin-top: 0;
			margin-left: 3px;
			@include media-query(767px) {
				margin-left: 1px;
			}
		}

		a {
			background: $white;
			font-size: 16px;
			font-size: calc_rem_value(16);
			font-weight: 600;
			color: $heading-color;
			padding: 13px 25px;
			border: 1px solid transparentize($theme-primary-color, 0.8);

			@include media-query(767px) {
				font-size: 13px;
				font-size: calc_rem_value(13);
				padding: 8px 12px;
				text-transform: none;
			}

			@include media-query(350px) {
				padding: 8px;
			}
		}

		.active a,
		a:hover {
			background: $theme-primary-color;
			color: $white;
		}
	}

	.tab-content {
		padding: 35px 40px;
		border: 1px solid transparentize($theme-primary-color, 0.8);
		margin-top: -1px;


		@include media-query(767px) {
			padding: 25px 20px;
		}

		.tab-pane p {
			margin-bottom: 20px;

			@include media-query(767px) {
				margin-bottom: 15px;
			}
		}

		.tab-pane p:last-child {
			margin-bottom: 0;
		}
	}
}
/*--------------------------------------------------------------
  #404 page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#10.1  error-404-section
--------------------------------------------------------------*/
.error-404-section {
  .error {
    width: 50%;
    float: left;

    @include media-query(991px) {
      width: 100%;
      float: none;
      text-align: center;
    }

    h2 {
      font-size: 200px;
      margin: 0;

      @include media-query(991px) {
        font-size: 150px;
      }

      @include media-query(767px) {
        font-size: 100px;
      }
    }
  }

  .error-message {
    width: 50%;
    float: left;
    margin-top: 40px;

    @include media-query(991px) {
      width: 100%;
      float: none;
      text-align: center;
      margin-top: 0;
    }

    h3 {
      font-size: 20px;
      margin: 0 0 0.8em;
    }

    p {
      margin-bottom: 1.8em;
    }
  }
}
/*--------------------------------------------------------------
	#home style 3
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#3.1	services-section-s3
--------------------------------------------------------------*/
.services-section-s3 {
	@extend .services-section;

	.grid {
		border: 0;
		padding: 55px 35px;

		@include media-query(767px) {
			padding: 35px 20px;
		}
	}

	.icon {
		@include background-style("../../images/service-icon-bg.png", center center, cover, no-repeat, local);
		width: 112px;
		height: 111px;

		@include media-query(767px) {
			width: 90px;
			height: 89px;
		}

		i {
			padding-left: 25px;
			padding-top: 15px;
			display: block;
		}
	}

	.owl-theme .owl-controls {
	    margin-top: 10px;
	}
}


/*--------------------------------------------------------------
#3.2	about-section-s3
--------------------------------------------------------------*/
.about-section-s3 {
	padding-top: 0;
	position: relative;
	z-index: 1;

	&:before {
		content: "";
		@include background-style("../../images/about-s3-shape.png", center center, cover, no-repeat, local);
		width: 714px;
		height: 875px;
		position: absolute;
		left: 0;
		top: -150px;
		z-index: -1;
	}

	.text {
		@include widther(1200px)  {
			padding-right: 280px;
		}

		> a {
			font-weight: bold;
			color: $heading-color;
			text-decoration: underline;
		}
	}

	.about-grids {
		margin-top: 65px;

		.grid {
			width: 25%;
			float: left;

			@include media-query(991px) {
				width: 50%;
			}

			@include media-query(500px) {
				width: 100%;
				float: none;
			}
		}
	}

	.last-grid {
		background: $white;
		min-height: 383px;
		display: table;
		text-align: center;
		padding: 25px;
		box-shadow: 0px 16px 57.85px 7.15px rgba(16, 37, 65, 0.23);

		@include media-query(1199px) {
			min-height: 315px;
		}
		@include media-query(500px) {
			height: 350px !important;
		}

		.middle-text {
			display: table-cell;
			vertical-align: middle;
		}

		h3 {
			font-size: 28px;
			font-weight: 900;
			margin: 0;
			line-height: 1.3em;

			@include media-query(767px) {
				font-size: 22px;
			}
		}
	}
}
/*--------------------------------------------------------------
#0.3	header
--------------------------------------------------------------*/
.site-header {

	.navigation {
		background-color: $white;
		margin-bottom: 0;
		border: 0;
		@include rounded-border(0);

		>.container {
			position: relative;
		}
	}

	#navbar {
		@include transition-time(0.5s);

		>ul li a:hover,
		>ul li a:focus {
			text-decoration: none;
			color: $heading-color !important;
		}

		// style for widther screen
		@include widther(992px) {
			li {
				position: relative;
			}

			>ul>li>a {
				font-size: 16px;
			}

			>ul .sub-menu {
				background-color: $theme-primary-color;
				width: 220px;
				position: absolute;
				left: 0;
				top: 100%;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				@include transition-time(0.3s);
			}

			>ul>li .sub-menu li {
				border-bottom: 1px solid #468dec;
			}

			>ul>li .sub-menu li a:hover {
				background-color: $theme-secondary-color;
			}

			>ul>li .sub-menu li:last-child {
				border-bottom: 0;
			}

			>ul>li .sub-menu a {
				font-size: 14px;
				font-weight: 600;
				display: block;
				padding: 12px 20px;
				color: $white;
			}

			>ul>li>.sub-menu .sub-menu {
				left: 100%;
				top: 0;
			}

			>ul>li>.sub-menu>.menu-item-has-children>a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					position: absolute;
					right: 15px;
					top: 50%;
					@include translatingY();
				}
			}

			/*** hover effect ***/
			>ul>li:hover>.sub-menu {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}

			.sub-menu>li:hover>.sub-menu {
				left: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(991px) {

			>ul>li a {
				display: block;
				font-size: 14px;
			}

			>ul>li .sub-menu li {
				border-bottom: 1px solid lighten($black, 90%);
			}

			>ul .sub-menu>li:last-child {
				border-bottom: 0;
			}

			>ul>li>.sub-menu a {
				padding: 8px 15px 8px 45px;
			}

			>ul>li>.sub-menu .sub-menu a {
				padding: 8px 15px 8px 65px;
			}

			>ul .menu-item-has-children>a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e61a";
					font-size: 11px;
					position: absolute;
					right: 15px;
					top: 50%;
					@include translatingY();
				}
			}
		}


		/*** mega-menu style ***/
		@include widther(992px) {
			.has-mega-menu {
				position: static;
			}

			.mega-menu,
			.half-mega-menu {
				background-color: $white;
				padding: 20px;
				border-top: 2px solid $theme-primary-color;
				position: absolute;
				right: 0;
				top: 100%;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				@include transition-time(0.3s);
			}

			.mega-menu {
				width: 1140px;
				right: 15px;
			}

			.half-mega-menu {
				width: 585px;
			}

			.mega-menu-box-title {
				font-size: 14px;
				text-transform: uppercase;
				font-weight: bold;
				display: block;
				padding-bottom: 7px;
				margin-bottom: 7px;
				border-bottom: 1px solid lighten($black, 90%);
			}

			.mega-menu-list-holder li a {
				font-size: 14px;
				display: block;
				padding: 7px 8px;
				margin-left: -8px;
			}

			/*** hover effect ***/
			.has-mega-menu:hover>ul {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(1199px) {
			>ul .mega-menu {
				width: 950px;
				right: 15px;
			}

			>ul .half-mega-menu {
				width: 485px;
			}
		}

		@include media-query(991px) {

			>ul .mega-menu,
			>ul .half-mega-menu {
				width: auto;
			}

			>ul .mega-menu .row,
			>ul .half-mega-menu .row {
				margin: 0;
			}

			.mega-menu-content>.row>.col {
				margin-bottom: 25px;
			}
		}

		@include media-query(991px) {
			.mega-menu .mega-menu-list-holder a {
				padding: 5px 15px 5px 40px;
			}

			.mega-menu .mega-menu-box-title {
				font-size: 14px;
				text-transform: uppercase;
				display: block;
				border-bottom: 1px dotted lighten($black, 70%);
				padding: 0 0 4px 5px;
				margin: 0 25px 8px 25px;
			}
		}





	}

	/* navigation open and close btn hide for width screen */
	@include widther(992px) {
		.navbar-header .open-btn {
			display: none;
		}

		#navbar .close-navbar {
			display: none;
		}
	}


	/* style for navigation less than 992px */
	@include media-query (991px) {
		.container {
			width: 100%;
		}

		.navbar-header button {
			background-color: $theme-primary-color;
			width: 40px;
			height: 35px;
			border: 0;
			padding: 5px 10px;
			outline: 0;
			position: absolute;
			right: 15px;
			top: 12px;
			z-index: 20;


			span {
				background-color: $white;
				display: block;
				height: 2px;
				margin-bottom: 5px;

				&:last-child {
					margin: 0;
				}
			}
		}

		#navbar {
			background: $white;
			display: block !important;
			width: 280px;
			height: 100% !important;
			margin: 0;
			padding: 0;
			border-left: 1px solid lighten($black, 80%);
			border-right: 1px solid lighten($black, 80%);
			position: fixed;
			right: -330px;
			top: 0;
			z-index: 100;

			ul a {
				color: $black;
			}

			ul a:hover,
			ul li.current a {
				color: $theme-primary-color;
			}

			.navbar-nav {
				height: 100%;
				overflow: auto;
			}

			.close-navbar {
				background-color: $white;
				width: 40px;
				height: 40px;
				color: $black;
				border: 0;
				outline: none;
				position: absolute;
				left: -41px;
				top: 90px;
				z-index: 20;

				.ti-close {
					position: relative;
					top: 1px;
				}
			}

			>ul>li {
				border-bottom: 1px solid lighten($black, 95%);
			}

			>ul>li>a {
				padding: 10px 15px 10px 35px;
			}
		}

		/* class for show hide navigation */
		.slideInn {
			right: 0 !important;
		}
	}

	@include media-query(767px) {
		.navbar-header .navbar-brand {
			font-size: 24px;
		}

		#navbar .navbar-nav {
			margin: 0;
		}
	}


	/*navbar collaps less then 992px*/
	@include media-query (991px) {
		.navbar-collapse.collapse {
			display: none;
		}

		.navbar-collapse.collapse.in {
			display: block;
		}

		.navbar-header .collapse,
		.navbar-toggle {
			display: block;
		}

		.navbar-header {
			float: none;
		}

		.navbar-right {
			float: none;
		}

		.navbar-nav {
			float: none;
		}

		.navbar-nav>li {
			float: none;
		}
	}
}

@include media-query(991px) {
	.page-wrapper {
		@include transition-time(0.3s);
	}

	.body-overlay:before {
		content: "";
		width: 100%;
		height: 100%;
		background: transparentize($black, 0.1);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		@include transition-time(0.3s);
	}
}


/*--------------------------------------------------------------
	#header-style-1
--------------------------------------------------------------*/
.header-style-1 {
	.topbar {
		display: none;
	}
}
.header-style-1 {
	@include widther(992px) {
		width: 100%;
		border-bottom: 1px solid transparentize(#57667e, 0.93);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 100;

		.navigation {
			background-color: transparent;
			padding: 0;
		}

		#navbar {
			margin-right: 180px;
			justify-content: flex-end;
		}

		#navbar>ul>li>a {
			font-size: 15px;
			font-weight: bold;
			color: $heading-color;
			padding: 40px 12px;
			display: block;
		}
	}

	@include widther(1200px) {
		#navbar>ul>.menu-item-has-children>a:before {
			font-family: "themify";
			content: "\e64b";
			font-size: 8px;
			position: absolute;
			right: 4px;
			top: 45%;
		}

		#navbar>ul>li>a {
			padding: 40px 20px;
		}
	}

	.navbar-brand {
		
		@include media-query(991px) {
			margin-top: 0;
			padding-top: 0;
			margin-bottom: 0px;
			margin-top: -30px;
			display: block;
		}
	}

	.navbar-brand img {
		max-width: 175px;
	}

	@include media-query(991px) {
		.navigation {
			padding: 15px 0;
		}
	}

	/*** search-social ***/
	.search-contact {
		position: absolute;
		right: 15px;
		top: 32px;

		@include media-query(991px) {
			right: 75px;
			top: 15px;
		}

		@include media-query(450px) {
			display: none;
		}

		>div {
			display: inline-block;
		}

		.open-btn {
			background-color: transparent;
			color: $heading-color;
			padding: 0;
			border: 0;
			margin-right: 22px;
			position: relative;
			top: -4px;

			@include media-query(1199px) {
				margin-right: 20px;
			}

			@include media-query(991px) {
				margin-right: 10px;
			}

			&:focus {
				border: 0;
				outline: 0;
				box-shadow: none;
			}
		}

		.contact {
			position: relative;
			top: -5px;
		}
	}
}


/*--------------------------------------------------------------
	#header-style-2
--------------------------------------------------------------*/
.header-style-2 {
	@extend .header-style-1;
	position: relative;
	border-bottom: 0;

	.topbar {
		display: block;
	}

	.topbar {
		padding: 10px 0;
		border-bottom: 1px solid #f0f0f0;

		@include media-query(767px) {
			padding: 20px 0;
		}

		@include media-query(767px) {
			text-align: center;
		}

		.social ul {
			overflow: hidden;

			@include media-query(767px) {
				display: inline-block;
			}
		}

		.social li {
			float: left;
		}

		.social ul>li+li {
			margin-left: 15px;
		}

		.social a {
			font-size: 14px;
			color: #9e9d9d;
		}

		.text {
			text-align: right;

			@include media-query(767px) {
				text-align: center;
			}

			span {
				font-weight: bold;
				color: $theme-primary-color;
				text-decoration: underline;
			}

			p {
				margin: 0;
			}
		}
	}
}



.header-search-form {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	background: transparentize($black, 0.35);
	opacity: 0;
	visibility: hidden;

	&.header-search-content-toggle {
		opacity: 1;
		visibility: visible;
	}

	.close-btn {
		position: absolute;
		right: 50px;
		top: 50px;
		color: #fff;
		cursor: pointer;
	}

	form {
		width: 500px;
		position: absolute;
		left: 50%;
		top: 50%;
		@include center-by-translating();

		@include media-query(767px) {
			width: 300px;
		}

		input {
			height: 60px;
			font-size: 16px;
			padding: 15px 25px;
			border: 0;
			box-shadow: none;
			border-radius: 60px;

			@include media-query(767px) {
				height: 45px;
				font-size: 17px;
			}
		}

		input:focus {
			box-shadow: none;
		}

		.btn {
			background-color: $theme-primary-color;
			width: 60px;
			height: 100%;
			color: $white;
			position: absolute;
			right: 0;
			top: 0;
			border: 0;
			border-radius: 0 60px 60px 0;
		}

		.btn:focus,
		.btn:active {
			outline: none;
			box-shadow: none;
		}
	}
}
.theme-accordion-s1 {
	margin-bottom: 0;

	.panel-default {
		background: transparent;
		border: 0;
		border-radius: 0;
		box-shadow: none;
	}

	.panel-heading {
		background-color: transparent;
		padding: 0;
		border-radius: 0;
	}

	.panel+.panel {
		margin-top: 15px;
	}

	.MuiPaper-root {
		margin-bottom: 30px;
		border: 0;
		box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);

		&:before {
			display: none;
		}
	}

	.MuiButtonBase-root {
		background: $white;
		font-size: 18px;

		color: $white;
		display: block;
		padding: 5px 25px;
		position: relative;

		p {
			font-size: 18px;

		}

		@include media-query(991px) {
			font-size: 16px;
			padding: 15px 25px;
		}

		@include media-query(767px) {
			padding: 12px 15px;
		}

		&:before {
			font-family: "themify";
			content: "\e64b";
			font-size: 15px;
			position: absolute;
			right: 25px;
			-webkit-transform: rotate(0);
			transform: rotate(0);
			@include transition-time(0.3s);
			color: $heading-color;
			top: 20px;

			@include media-query(991px) {
				font-size: 18px;

				right: 20px;
			}
		}

		&:focus {
			text-decoration: none;
		}

		&.Mui-expanded {
			background: $theme-primary-color;

			p {
				color: $white;
			}

			&:before {
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
				color: $white;
			}
		}
	}

	.MuiAccordionDetails-root .MuiTypography-root {
		padding: 20px;
		margin-bottom: 0;
	}
}
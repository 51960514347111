.sticky-header {
	background: $white;
	width: 100%;
	position: fixed;
	left: 0;
	top: -200px;
	z-index: 9999;
	opacity: 0;
	@include transition-time(0.7s);
	box-shadow: 0 3px 15px rgba(0,0,0, 0.1);
}

.sticky-on {
	opacity: 1;
	top: 0;
}

.site-header .sticky-header {
	background-color: $white;
}
